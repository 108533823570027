import {APP_VARIABLES} from '@/constants/app-variable';
import _ from 'lodash';

import menuItems from "@/constants/sidebar-menu";
import moment from 'moment-timezone'

//default timezone updated
moment.tz.setDefault('Australia/Melbourne');

class globalHelper {

  getConstantsValue(cName) {
    switch (cName) {
      case 'curUser':
        return APP_VARIABLES.currentUser;
      case 'lastSeenPage':
        return APP_VARIABLES.lastSeenPage;
      default:
        return cName;
    }
  };

  getCurData(dataType) {
    switch (dataType) {
      case 'curUser':
        return JSON.parse(localStorage.getItem(APP_VARIABLES.currentUser)) || null;
      case 'curToken':
        return this.getCurData('curUser')?.token || '';
      case 'curUserId':
        return this.getCurData('curUser')?.id || '';
      case 'curUserRole':
        return this.getCurData('curUser')?.roles || [];
      case 'curUserPermissions':
        return this.getCurData('curUser')?.permissions || [];
      case 'lastSeenPage':
        return localStorage.getItem(APP_VARIABLES.lastSeenPage) || '';
      default:
        break;
    }
  }

  lsPush(name, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    localStorage.setItem(this.getConstantsValue(name), value);
  }

  updateLSData(changeDatas, name = "curUser") {
    const updateData = {...getCurData(name), ...changeDatas};
    localStorage.setItem(this.getConstantsValue(name), JSON.stringify(updateData));
  }

  setURLparams(dataObj, link, type) {
    let url = type ? link : new URL(link);
    if (type) {
      const params = Object.keys(dataObj).map(key => dataObj[key] ? key + '=' + dataObj[key] : '').join('&');
      url = url+params;
      return url;
    }
    Object.keys(dataObj).map(key => {
      if ((Array.isArray(dataObj[key]) && dataObj[key].length) || (!Array.isArray(dataObj[key]) && dataObj[key])) {
        url.searchParams.set(key, dataObj[key]);
      }
    });
    return url.toString();
  }

  getCurrRouteDetails (routeName) {
    const menuIndex = menuItems.findIndex(item => {
      if (item.children) {
        return item.children.some(child => {
          if (Array.isArray(child.route) && child.route.length) {
            return child.route.indexOf(routeName) !== -1;
          } else {
            return child.route === routeName;
          }
        });
      }
      return item.route === routeName;
    });
    return menuIndex === -1 ? null : (menuItems[menuIndex].children ?
        (menuItems[menuIndex].children.filter(child => {
        if (Array.isArray(child.route) && child.route.length) return child.route.indexOf(routeName) !== -1;
        else return child.route === routeName; }))[0] : menuItems[menuIndex])
  }

  getMomentDatas(format = null, date = new Date()) {
    let _moment = moment(date);

    if (format) {
      _moment = _moment.format(format);
    }

    return _moment;
  }

  getMomentDiff(toDate, getType = "days", fromDate = new Date()) {
    const startDate = this.getMomentDatas(null, fromDate),
      endDate = this.getMomentDatas(null, toDate);

    return startDate.diff(endDate, getType);
  }

  goDateGvnType(value, format, goType = 'days', type = 'add', date = new Date()) {
    let _moment = this.getMomentDatas(null, date)[type](value, goType);

    if (format) {
      _moment = _moment.format(format);
    }

    return _moment;
  }

  givenTimeRangeCheck (toDate=new Date(), fromDate=new Date()) {
    const currentTime = moment(),
      beforeTime = moment(fromDate),
      afterTime  = moment(toDate);

    return moment({
        hour: currentTime.hour(),
        minute: currentTime.minute(),
        second: currentTime.second()
      }).isBetween(beforeTime, afterTime);
  }

  givenSecToTime(seconds) {
    return moment()
      .startOf("day")
      .seconds(seconds)
      .format("HH:mm:ss");
  }


  addHoursnDDateJs(dateName, value, date=null) {
    const currDate = date ? new Date(date) : new Date();
    switch (dateName) {
      case "hours":
        if (typeof value == 'number') {
          return currDate.setHours(currDate.getHours() + value);
        }
        return new Date(currDate.setHours(value.h, value.m, value.s, value.ms));
      case "date":
        return new Date(currDate.setDate(currDate.getDate() + value));
      default:
        return date;
    }
  }

  errorMsg(name, model, fieldName, customMsg) {
    const errors = [];
    if (!model.$dirty) return errors;
    switch (name) {
      case "name":
        !model.required && errors.push(`${fieldName} is required`);
        break;
      case "email":
        !model.email && errors.push("Enter a valid email address");
        model.required !== undefined && !model.required && errors.push(`${fieldName} is required`);
        break;
      case "sameas":
        !model.sameAsfield && errors.push(`${fieldName} does not match`);
        !model.required && errors.push(`${fieldName} is required`);
        break;
      case "number":
        !model.required && errors.push(`${fieldName} is required`);
        !model.minValue && errors.push(`${fieldName} must be greater than zero`);
        customMsg && !model.maxValue && errors.push(`${fieldName} must be less than ${customMsg}`);
        break;
      case "mobile":
        !model.required && errors.push(`${fieldName} is required`);
        break;
      default:
        break;
    }

    return errors;
  }

  insertObjValue(originalObj, updateObj, insertObj = null) {
    Object.keys(updateObj).forEach(obj => {
      if (insertObj && insertObj.hasOwnProperty(obj) || originalObj.hasOwnProperty(obj)) {
        originalObj[obj] = updateObj[obj];
      }
    });

    return originalObj;
  }

  resetObjValue(originalObj, defaultObj) {
    const outputObject = {};
    Object.keys(originalObj).forEach(obj => outputObject[obj] = (defaultObj.hasOwnProperty(obj) ? defaultObj[obj] : ''));

    return outputObject;
  }

  getChangedObjVal(originalObj, updateObj, reqKeys = null) {
    const outputObject = {};
    Object.keys(originalObj).forEach(obj => {
      if (JSON.stringify(originalObj[obj]) !== JSON.stringify(updateObj[obj]) || (reqKeys && reqKeys === obj)) {
        outputObject[obj] = updateObj[obj]
      }
    });

    return _.isEmpty(outputObject) ? null : outputObject;
  }

  focusErrorElement(element, refs) {
    for (let key in Object.keys(element)) {
      const input = Object.keys(element)[key];
      if (input.includes("$")) return false;

      if (element[input].$error) {
        refs[input].focus();
        break;
      }
    }
  }

  createVideoEl(src) {
    const video = document.createElement('video'),
      source = document.createElement('video');
    source.src = src;
    source.type = 'video/mp4';
    video.controls = true;
    video.currentTime = 1;
    video.crossOrigin = 'anonymous';
    video.appendChild(source);
    return video
  }

  captureImgFromVdo(video=null, scaleFactor, videoSrc=null) {
    if (videoSrc) video = this.createVideoEl(videoSrc);
    if (scaleFactor == null) {
        scaleFactor = 1;
    }
    const w = video.videoWidth * scaleFactor,
        h = video.videoHeight * scaleFactor,
        canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);

    return canvas.toDataURL();
}

  cloneData(value) {
    return JSON.parse(JSON.stringify(value));
  }

  getMomentMMYYLists(count=null, format='MMMM') {
    if (count) {
      return Array.apply(0, Array(count)).map(function(_,i){return moment().add(i, 'y').format(format)});
    }
    return Array.apply(0, Array(12)).map(function(_,i){return moment().month(i).format(format)});
  }

  getJsDateBeginEnd (type=null, date = null, field='day') {
    const _moment = date ? moment(date) : moment();
    if(type) return new Date(_moment.startOf(field).toString());
    return new Date(_moment.add(1, 'days').endOf(field).toString());
  }

  copyTextFunc(refEl, type=null) {
    let element;
    if(type) {
      element = document.createElement('textarea');
      element.class="d-none"
      element.value = refEl.$el.innerText;
      document.getElementById(refEl.$el.id).appendChild(element);
    } else {
      element = refEl;
    }
    element.select();
    element.setSelectionRange(0, 99999);
    document.execCommand('copy');
    if(type) document.getElementById(refEl.$el.id).removeChild(element);
  }

  removeLSStorage(name) {
    localStorage.removeItem(this.getConstantsValue(name));
  }

  logout() {
    localStorage.setItem(APP_VARIABLES.lastSeenPage, location.pathname);
    localStorage.removeItem(APP_VARIABLES.currentUser);
  }
}

export default new globalHelper();