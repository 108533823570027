import http from '@/http';

class quote {
    getQuoteEvaluate(scanId, data) {
        return http('scan').get(`scans/${scanId}/evaluate`, data);
    }
    createQuoteModel(data) {
        return http('quote').post('quotes', data);
    }
    searchQuoteModel(data) {
        return http('quote').post('quotes/search', data);
    }
    getQuoteModel(endPoint, data) {
        return http('quote').post(endPoint, data);
    }
    quoteModel(quoteId) {
        return http('quote').get(`quotes/${quoteId}`);
    }
    deleteQuoteModel(quoteId) {
        return http('quote').deleteReq(`quotes/${quoteId}`)
    }
}

export default new quote();