import globalHelper from '@/helpers/global.helper';

import auth from '@/store/modules/auth/api';

export default {
    login({ commit }, data) {
        commit("setLoading", true);
        return new Promise((res, rej) => {
            auth.authLogin(data).then(response => {
                commit("setCurrentUser", response);
                globalHelper.lsPush("curUser", response);
                commit("snackbar/SHOW_MESSAGE", {
                  text: "Login Successfully",
                  color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setLoading", false);
                rej(error)
            });
        });
    },

    getLoggedUser({ commit }) {
        return new Promise((res, rej) => {
            auth.authUser(data).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },

    sendResetLink({ commit }, data) {
        return new Promise((res, rej) => {
            auth.authForgetPassword(data).then(response => {
              commit("snackbar/SHOW_MESSAGE", {
                  text: "Resent Link Send Successfully",
                  color: "success",
              });
              res(response);
          }).catch(() => {
              rej(error)
          });
        });
    },

    resetPassword({ commit, dispatch }, data) {
        return new Promise((res, rej) => {
            auth.authResetPassword(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Password Changed Successfully",
                    color: "success",
                });
            res(response);
          }).catch(() => {
              rej(error)
          });
        });
    }
}