import http from '@/http';

class clients {
    clientsList(params) {
        return http('quote').get('clients/lists', params);
    }
    pdrList() {
        return http('quote').post('panels/lists');
    }
    clientModel(params) {
        return http('quote').get(`clients/${params.id}`, params);
    }
    createClientModel(params) {
        return http('quote').post('clients', params);
    }
    updateClientModel(params) {
        return http('quote').put(`clients/${params.id}`, params);
    }
    deleteClientModel(id) {
        return http('quote').deleteReq(`clients/${id}`)
    }
}

export default new clients();