import Vue from 'vue';
import Vuex from 'vuex';

import themeConfig from '@/store/themeConfig';
import commonModules from '@/store/common-modules';

import modules from '@/store/modules';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    themeConfig,
    commonModules,
    modules
  },
})
