import VuePerfectScrollbar from "vue-perfect-scrollbar";

// theme Main scss
import "@/assets/scss/index.scss";

// plugins menu

// VueCalendar
import "@/plugins/calendar.js";

// axios
import axios from "axios";
import VueAxios from "vue-axios";

// form-Wizard
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import VueMeta from "vue-meta";

//validation
import Vuelidate from 'vuelidate';

//Quill Editor Box
import VueViewer from 'v-viewer';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
// end plugins

export default {
  install(Vue) {
    Vue.use(VueMeta);
    Vue.use(VueFormWizard);
    Vue.use(VueAxios, axios);
    Vue.use(Vuelidate);
    Vue.use(VueViewer);
    Vue.use(VueQuillEditor)
    Vue.component(
      "VerticalSix",
      // The `import` function returns a Promise.

      () => import("@/layouts/component/Index")
    );

    Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);
  },
};
