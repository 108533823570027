import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: window.VUE_APP_PUSHER_KEY,
  wsHost: window.VUE_APP_PUSHER_HOST,
  wsPort: window.VUE_APP_PUSHER_PORT,
  wssPort: window.VUE_APP_PUSHER_PORT,
  disableStats: false,
  forceTLS: window.VUE_APP_PUSHER_SSL,
  enabledTransports: window.VUE_APP_PUSHER_SSL ? ['wss','ws'] : ['ws'],
  withoutInterceptors: true
});