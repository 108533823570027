<template>
  <v-app>
    <router-view/>
    <Snackbar></Snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: "Insurance",
      titleTemplate: `%s | Scan N Go`,
    };
  },
  components: {
    Snackbar: () => import("@/components/base/Snackbar"),
  }
};
</script>