import axios from 'axios';

import globalHelper from '@/helpers/global.helper';
import { ErrorValidation } from '@/http/error-interceptor';
import router from '@/router';


const AxiosInstance = (msName = null) => {
    const $axios = axios.create({
        withCredentials: true
    });

    if(msName){
        $axios.defaults.baseURL = `${window.VUE_APP_MS_URL}${msName}/`;
    }

    // Add a request interceptor
    $axios.interceptors.request.use((conf) => {
        const currentToken = globalHelper.getCurData('curToken');
        if (currentToken) {
            conf.headers.Authorization = 'Bearer ' + currentToken;
        }
        return conf;
    }, (error) => {
        // Do something with request error
        return Promise.reject(error);
    });

    // After API response Callback
    $axios.interceptors.response.use(response => {
        return response.data && (response.data.data && !response.data.meta) ? response.data.data : response.data ? response.data : null;
    }, async (error) => {
        await ErrorValidation(error);
        // Do something with response error
        return Promise.reject(error);
    });
 
    return $axios;
}


export default AxiosInstance;
