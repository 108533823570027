import Vue from "vue";
import Router from "vue-router";

import globalHelper from "@/helpers/global.helper";
import menuItems from "@/constants/sidebar-menu";

import { PageNotFound, AccessDenied } from "@/pages/error";

Vue.use(Router);

const checkAccessURLs = (to) => {
  const userAccessPermission = globalHelper.getCurData('curUserPermissions');
  if (globalHelper.getCurData('curUser') && (userAccessPermission.indexOf(to.meta.access) !== -1 || !to.meta.access)) return true;
  else return false;
};

const router = new Router({
  mode: "history",
	saveScrollPosition: true,
  routes: [
    {
      path: '/login',
      component: () => import('@/pages/auth/Login'),
      beforeEnter: (to, from, next) => {
        if (globalHelper.getCurData('curUser')) {
          next('/');
        } else {
          next();
        }
      }
    }, {
      path: '/forgot-password',
      component: () => import('@/pages/auth/ForgotPassword'),
      alias: ['/reset-password'],
      props: route => ({ query: route.fullPath === '/forgot-password' ? null : route.query.token}),
      beforeEnter: (to, from, next) => {
        if (to.path === '/reset-password' && !to.query.token) {
          next('/error');
        } else {
          next();
        }
      }
    }, {
      path: "/error-403", name: "Error 403",
      component: AccessDenied,
    }, {
      path: "/error", name: "Error 404",
      component: PageNotFound,
    }, {
      path: "/home",
      redirect: "/",
    }, {
      path: "/dashboard",
      redirect: "/",
    }, {
      path: "/",
      component: () => import("@/pages/index"),
      children: [
        {
          path: '/update-profile', name: 'Update Profile', meta:{ access: 'user'},
          component: () => import("@/pages/configuration/users/components/UpdateProfile"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
        }, {
          path: '/users', name: 'Users', meta:{ access: 'user'},
          component: () => import("@/pages/configuration/users"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':userId', name: 'Update User', meta:{ access: 'user', indexScreen: 'User' }, component: () => import("@/pages/configuration/users")},
            { path: 'create', name: 'Create User', meta:{ access: 'user'}, component: () => import("@/pages/configuration/users")}
          ]
        },  {
          path: '/roles', name: 'Roles', meta:{ access: 'role'},
          component: () => import("@/pages/configuration/roles"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':roleId', name: 'Update Role', meta:{ access: 'role', indexScreen: 'Role' }, component: () => import("@/pages/configuration/roles")},
            { path: 'create', name: 'Create Role', meta:{ access: 'role'}, component: () => import("@/pages/configuration/roles")}
          ]
        },  {
          path: '/permissions', name: 'Permissions', meta:{ access: 'permission'}, 
          component: () => import("@/pages/configuration/permissions"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':permissionId', name: 'Update Permission', meta:{ access: 'permission', indexScreen: 'Permission' }, component: () => import("@/pages/configuration/permissions")},
            { path: 'create', name: 'Create Permission', meta:{ access: 'permission'}, component: () => import("@/pages/configuration/permissions")}
          ]
        }, {
          path: '/devices', name: 'Devices', meta:{ access: 'device'},
          component: () => import("@/pages/setting/devices"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':deviceId', name: 'Update Device', meta:{ access: 'device', indexScreen: 'Device' }, component: () => import("@/pages/setting/devices")},
            { path: 'create', name: 'Create Device', meta:{ access: 'device'}, component: () => import("@/pages/setting/devices")}
          ]
        }, {
          path: '/locations', name: 'Locations', meta:{ access: 'location'},
          component: () => import("@/pages/setting/locations"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':locationId', name: 'Update Location', meta:{ access: 'location', indexScreen: 'Location' }, component: () => import("@/pages/setting/locations")},
            { path: 'create', name: 'Create Location', meta:{ access: 'location'}, component: () => import("@/pages/setting/locations")}
          ]
        }, {
          path: '/scans', name: 'Scans', meta:{ access: 'scan'},
          component: () => import("@/pages/scans"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':scanId', name: 'Update Scan', meta:{ access: 'scan', indexScreen: 'Scan' }, component: () => import("@/pages/scans")}
          ],
        }, {
          path: '/quotes', name: 'Quotes', meta:{ access: 'scan'},
          component: () => import("@/pages/quote"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':quoteScanId', name: 'Quote Generate', meta:{ access: 'scan', indexScreen: 'Scan' }, component: () => import("@/pages/quote")},
            { path: ':quoteScanId/invoice', name: 'Quote Invoice', meta:{ access: 'scan', indexScreen: 'quote' }, component: () => import("@/pages/quote")}
          ],
        },
        {
          path: '/clients', name: 'Clients', meta:{ access: 'client'},
          component: () => import("@/pages/setting/clients"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':clientId', name: 'Update Client', meta:{ access: 'client', indexScreen: 'Client' }, component: () => import("@/pages/setting/clients")},
            { path: 'create', name: 'Create Client', meta:{ access: 'client'}, component: () => import("@/pages/setting/clients")}
          ]
        }, {
          path: '/products', name: 'Products', meta:{ access: 'product'},
          component: () => import("@/pages/setting/products"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':productId', name: 'Update Product', meta:{ access: 'product', indexScreen: 'Product' }, component: () => import("@/pages/setting/products")},
            { path: 'create', name: 'Create Product', meta:{ access: 'product'}, component: () => import("@/pages/setting/products")}
          ]
        }, {
          path: '/parts', name: 'Parts', meta:{ access: 'part'},
          component: () => import("@/pages/setting/parts"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':partId', name: 'Update Part', meta:{ access: 'part', indexScreen: 'Part' }, component: () => import("@/pages/setting/parts")},
            { path: 'create', name: 'Create Part', meta:{ access: 'part'}, component: () => import("@/pages/setting/parts")}
          ]
        }, {
          path: '/agents', name: 'Agents', meta:{ access: 'agent'},
          component: () => import("@/pages/setting/agents"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':agentId', name: 'Update Agent', meta:{ access: 'agent', indexScreen: 'Agent' }, component: () => import("@/pages/setting/agents")},
            { path: 'create', name: 'Create Agent', meta:{ access: 'agent'}, component: () => import("@/pages/setting/agents")}
          ]
        }, {
          path: '/panelgroups', name: 'Panel Groups', meta:{ access: 'part'},
          component: () => import("@/pages/setting/panel-groups"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':panelGroupId', name: 'Update Panel Group', meta:{ access: 'panelgroup', indexScreen: 'Panelgroup' }, component: () => import("@/pages/setting/panel-groups")},
            { path: 'create', name: 'Create Panel Group', meta:{ access: 'panelgroup'}, component: () => import("@/pages/setting/panel-groups")}
          ]
        }, {
          path: '/panels', name: 'Panels', meta:{ access: 'part'},
          component: () => import("@/pages/setting/panels"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':panelId', name: 'Update Panel', meta:{ access: 'panel', indexScreen: 'Panel' }, component: () => import("@/pages/setting/panels")},
            { path: 'create', name: 'Create Panel', meta:{ access: 'panel'}, component: () => import("@/pages/setting/panels")}
          ]
        }, {
          path: '/email-templates', name: 'Email Templates', meta:{ access: 'email_template'},
          component: () => import("@/pages/setting/email/template"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
          children: [
            { path: ':emailTemplateId', name: 'Update Email Template', meta:{ access: 'email_template', indexScreen: 'Email Templates' }, component: () => import("@/pages/setting/email/template")},
            { path: 'create', name: 'Create Email Template', meta:{ access: 'email_template'}, component: () => import("@/pages/setting/email/template")}
          ]
        }, {
          path: '/email-header-footer', name: 'Email Header & Footer', meta:{ access: 'email_template'},
          component: () => import("@/pages/setting/email/header-footer"),
          beforeEnter: (to, from, next) => {
            if (checkAccessURLs(to)) next();
            else next('/');
          },
        }, {
            path: '/customer-portal/:agentId', name: 'Customer Portal',
            component: () => import("@/pages/customer-portal"),
            children: [
                {
                    path: ':scanId', name: 'Updated Scan', meta:{ access: 'scan', indexScreen: 'Scan' },
                    component: () => import("@/pages/customer-portal")
                },
            ]
        }, {
          path: "/", name: 'Dashboard', meta: { access: 'scan'},
          component: () => import("@/pages/dashboard"),
          beforeEnter: (to, from, next) => {
            const userAccessPermission = globalHelper.getCurData('curUserPermissions'),
                route = menuItems.find(item => (item.childAccess && item.childAccess.indexOf(userAccessPermission[0]) !== -1) ||
                    item.access === userAccessPermission[0]),
                navRoute = route.children ? route.children.find(item => (item.childAccess && item.childAccess.includes(userAccessPermission[0])) ||
                    (item.access && item.access === userAccessPermission[0])) : route;
            if (globalHelper.getCurData('curUser')) {
                if (checkAccessURLs(to)) next();
                else if (navRoute) next(`/${navRoute.childAccess ? navRoute.children[0].to : navRoute.to}`);
                else next('/error-403');
            } else next('/login');
          },
        },{
          path: "**", redirect: '/'
        }
      ]
    }
  ],
});

export default router;
