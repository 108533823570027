<template>
  <div>
    <v-subheader v-if="item.divider" class="text-12 text-uppercase mt-8">{{
      item.divider
    }}</v-subheader>
    <v-list-group
      :sub-group="subGroup"
      no-action
      v-model="listModel"
      flat
      class="pl-0 base-item-group mb-2"
      active-class="mb-2"
    >
      <v-icon v-if="item.icon" color="black" slot="prependIcon" small style="transform: rotate(0) !important">{{ item.icon }}</v-icon>
      <template v-slot:activator>
        <v-list-item-icon
          v-if="text"
          class="v-list-item__icon--text"
          v-text="computedText"
        />

        <v-list-item-content>
          <v-list-item-title
            class="text-13 black--text vertical-sidebar-text"
            v-text="item.title"
          />
        </v-list-item-content>

        <v-icon v-if="subGroup" color="black" slot="appendIcon">{{ `mdi-chevron-${listModel ? 'up' : 'down'}` }}</v-icon>

      </template>

      <template v-for="(child, i) in children">
        <base-item-sub-group-two
          v-if="child.children && child.childAccess.some(item => userAccessPermission.includes(item))"
          :key="`sub-group-${i}`"
          :item="child"
          class="pl-0"
        />

        <base-item-two
          class="pl-2"
          v-else-if="!child.children && userAccessPermission.includes(child.access)"
          :key="`item-${i}`"
          :item="child"
          :text="false"
        />
      </template>
    </v-list-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// Utilities
import kebabCase from "lodash/kebabCase";
import Themeable from "vuetify/lib/mixins/themeable";
export default {
  name: "ItemGroupTwo",
  mixins: [Themeable],

  inheritAttrs: false,
  data: (vm) => ({
    userAccessPermission: vm.$helpers.getCurData('curUserPermissions'),
    listModel: 0,
    miniVariant: false,
  }),
  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        subHeader: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters(["getThemeMode"]),
    getMiniVariant() {
      return this.miniVariant;
    },
    children() {
      return this.item.children;
    },
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    group() {
      return this.genGroup(this.item.children);
    },
  },
  methods: {
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join("|");
    },
    routeCheck(menuItem, routeName) {
        return menuItem && ((typeof menuItem.route === 'string' && menuItem.route === routeName ) ||
            (Array.isArray(menuItem.route) && menuItem.route.length && menuItem.route.some(item => item === routeName)))
    }
  },
  watch: {
    '$route': {
        handler: function(route) {
            if(this.item && ((this.item.children && this.item.children.length &&
                this.item.children.some(item => (item.children && item.children.length &&
                item.children.some(childItem => this.routeCheck(childItem, route.name))) || this.routeCheck(item, route.name))) ||
                (this.routeCheck(this.item, route.name)))) {
                    this.listModel = 1;
            } else {
                this.listModel = 0;
            }
        },
        deep: true,
        immediate: true
      }
  }
};
</script>

<style lang="scss">
.base-item-group {
  .v-list-item__icon {
    margin-right: 0.9375rem !important;
    min-width: 0;
  }
}
.main-menu-title {
  text-transform: uppercase;
  font-size: 12px;
  padding: 16px 0 16px 16px;
  display: block;
  font-weight: 500;
  letter-spacing: 0.5px;
}
// .vertical-sidebar-text {
//   color: #989eb3;
// }
</style>
