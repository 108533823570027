import Vue from 'vue';

//NPM Style Sheets
import 'vuetify/dist/vuetify.min.css';
import 'viewerjs/dist/viewer.css';

//Component Imports
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import globalHelper from '@/helpers/global.helper';

//Theme plugin Imports
import vuetify from '@/plugins/vuetify';
import '@/plugins';
import themeKit from '@/plugins/theme.kit';
import filters from '@/filters';
import vGoogleTranslate from 'v-google-translate';

Vue.use(vGoogleTranslate);

Vue.use(themeKit);
Vue.config.productionTip = false;

Vue.use({
  install() {
    Vue.helpers = globalHelper
    Vue.prototype.$helpers = globalHelper
    Vue.filters = filters
    Vue.prototype.$filters = filters
    Vue.vm = Vue
    Vue.prototype.$vm = Vue
  }
});

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
